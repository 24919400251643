import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from "redux";
import { connect } from "react-redux";

import Layout from "../includes/Layout"

import * as actions from "../../actions";


const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

export const renderInput = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => (
		<Input
			{...input}
			{...custom}
		/>
	)

class EditVendor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addressLineOne: "",
			addressLineTwo: "",
			country: "",
			locality: "",
			region: "",
			description: "",
			organization_name: "",
			phone_number: "",
			email: "",
			user: ""
		}
	}
	onSubmit = async () => {
		const { match: { params: { vendor_id } } } = this.props
		const address = {
			addressLineOne: this.state.addressLineOne,
			addressLineTwo: this.state.addressLineTwo,
			country: this.state.country,
			locality: this.state.locality,
			region: this.state.region,
		}
		const data = this.state;

		data.address = address;
		await this.props.updateVendor(vendor_id, data, this.state.user);
		if (!this.props.errorMessage)
			this.props.history.push(`/vendors/${vendor_id}/agents`);
	}
	async componentDidMount() {
		await this.props.getVendor(this.props.match.params.vendor_id);
		let { vendor } = this.props
		vendor = vendor[0];
		console.log(vendor)
		this.setState({
			addressLineOne: vendor.address ? vendor.address.addressLineOne : "",
			addressLineTwo: vendor.address ? vendor.address.addressLineTwo : "",
			country: vendor.address ? vendor.address.country : "",
			locality: vendor.address ? vendor.address.locality : "",
			region: vendor.address ? vendor.address.region : "",
			description: vendor.description,
			organization_name: vendor.organization_name,
			phone_number: vendor.phone_number,
			email: vendor.email,
			user: vendor.user
		});
	}
	render() {
		const { classes } = this.props;
		return (
			<Layout>
				<CssBaseline />
				<Paper className={classes.paper}>
					<Typography component="h1" variant="h5">
						Edit Vendor Information
          			</Typography>
					<form className={classes.form}>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="organization_name">Organizaion name</InputLabel>
							<Input
								id="organization_name"
								name="organization_name"
								value={this.state.organization_name}
								onChange={e => this.setState({ organization_name: e.target.value })}
							/>
						</FormControl>
						<FormControl margin="normal" fullWidth>
							<InputLabel htmlFor="email">Email Address</InputLabel>
							<Input
								id="email"
								name="email"
								value={this.state.email}
								onChange={e => this.setState({ email: e.target.value })}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="phone_number">Phone Number</InputLabel>
							<Input
								id="phone_number"
								name="phone_number"
								value={this.state.phone_number}
								onChange={e => this.setState({ phone_number: e.target.value })}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="addressLineOne">Address Line One</InputLabel>
							<Input
								id="addressLineOne"
								name="addressLineOne"
								value={this.state.addressLineOne}
								onChange={e => this.setState({ addressLineOne: e.target.value })}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="addressLineTwo">Address Line Two</InputLabel>
							<Input
								id="addressLineTwo"
								name="addressLineTwo"
								value={this.state.addressLineTwo}
								onChange={e => this.setState({ addressLineTwo: e.target.value })}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="locality">Locality</InputLabel>
							<Input
								id="locality" name="locality"
								value={this.state.locality}
								onChange={e => this.setState({ locality: e.target.value })}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="region">Region</InputLabel>
							<Input
								id="region"
								name="region"
								value={this.state.region}
								onChange={e => this.setState({ region: e.target.value })}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="country">Country</InputLabel>
							<Input
								id="country"
								name="country"
								value={this.state.country}
								onChange={e => this.setState({ country: e.target.value })}
							/>
						</FormControl>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="description">Description</InputLabel>
							<Input
								id="description"
								name="description"
								value={this.state.description}
								onChange={e => this.setState({ description: e.target.value })}
							/>
						</FormControl>

						{
							this.props.errorMessage ?
								<Typography variant="caption" gutterBottom>
									{this.props.errorMessage}
								</Typography> :
								null
						}

						<Button
							type="button"
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={this.onSubmit}
						>
							Update
            			</Button>
					</form>
					{
						this.props.errorMessage ?
							<Typography>{this.props.errorMessage}</Typography> : ''
					}
				</Paper>

			</Layout>
		);
	}


}

EditVendor.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		errorMessage: state.vend.errorMessage,
		id: state.auth.id,
		vendor: state.vend.vendor,
	}
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(EditVendor);