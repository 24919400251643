import {
    AUTH_LOGIN,
    AUTH_ERROR,
    AUTH_LOGOUT,
    PROFILE
} from "../actions/types";

const DEFAULT_STATE = {
    isAuthenticated: false,
    token: '',
    role: '',
    id: '',
    errorMessage: '',
    profile: {}
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case AUTH_LOGIN:
            return { ...state,
                token: action.payload.token,
                isAuthenticated: true,
                role: action.payload.user.role,
                id: action.payload.profile._id,
                errorMessage: ''
            }
        case AUTH_LOGOUT:
            return { ...state,
                token: '',
                isAuthenticated: false,
                role: '',
                user: '',
                errorMessage: ''
            }
        case PROFILE:
            return {
                ...state,
                profile: action.payload
            }
        case AUTH_ERROR:
             
            return { ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }

}