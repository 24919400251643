import {
    BSTAFF_LIST,
    BSTAFF_ERROR,
    BSTAFF
} from "../actions/types";

const DEFAULT_STATE = {
    bstaff: {},
    bstaffs: [],
    errorMessage: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case BSTAFF_LIST:
            return { ...state,
                bstaffs: action.payload,
                errorMessage: ''
            }
        case BSTAFF : {
            return {
                ...state,
                bstaff : action.payload,
                errorMessage: ''
            }
        }
        case BSTAFF_ERROR:
             
            return { ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }

}