import React, { Component } from "react";
import { connect } from 'react-redux';

export default (OriginalComponent)=> {
    class MixedComponent extends Component{
        protectedCheck(){
            if(!this.props.isAuth || !this.props.jwtToken || this.props.role === "Vendor"){
                this.props.history.push('/vendors/'+this.props.id+"/agents")
            }
        }

        componentDidMount(){
            this.protectedCheck();
        }

        componentDidUpdate(){
            this.protectedCheck();
        }
        render(){
            return <OriginalComponent {...this.props} />
        }
    }
    function mapStateToProps(state) {
        return {
            isAuth: state.auth.isAuthenticated,
            jwtToken : state.auth.token,
            role: state.auth.role,
            id : state.auth.id
        }
    }

    return connect(mapStateToProps)(MixedComponent);
}

