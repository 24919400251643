import {
    PHASE_LIST, PHASE_MEDIA
} from "../actions/types";

const DEFAULT_STATE = {
    agent: {},
    agent_new: null,
    medias: [],
    errorMessage: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case PHASE_LIST:
            return { ...state,
                agents: action.payload,
                errorMessage: ''
            }
        case PHASE_MEDIA:
            return {
                ...state,
                medias: action.payload,
                errorMessage:""
            }
        default:
            return state;
    }

}