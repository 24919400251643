import {
    AGENT_CREATE,
    AGENT_ERROR,
    AGENT_LIST,
    AGENT
} from "../actions/types";

const DEFAULT_STATE = {
    agent: {},
    agent_new: null,
    agents: [],
    errorMessage: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case AGENT_CREATE:
            return { ...state,
                agent_new: action.payload,
                vendors: action.payload.agents,
                errorMessage: ''
            }
        case AGENT_LIST:
            return { ...state,
                agents: action.payload,
                errorMessage: ''
            }
        case AGENT:
            return { ...state,
                agent: action.payload,
                errorMessage: ''
            }
        case AGENT_ERROR:
             
            return { ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }

}