import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import Layout from '../includes/Layout';
import AgentList from './list';
import RemoveDialogue from '../agents/removeDialogue';
import * as actions from '../../actions';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class Agents extends React.Component {
    constructor(props) {
        super(props);
        this.gotoCreate = this.gotoCreate.bind(this);
        this.state = {
            agent: []
        }
    }

    async componentDidMount() {
        await this.props.getAgent(this.props.match.params.agent_id);
        this.setState({
            agent: this.props.agent
        })
    }

    componentDidUpdate() {
    }

    gotoCreate() {
        this.props.history.push(`/vendors/${this.props.match.params.vendor_id}/agents/new`)
    }
    render() {
        const { classes } = this.props;
        return (
            <Layout>
                <Paper className={classes.root} elevation={1}>
                    <Typography variant="h5" component="h3">
                        {this.props.agent.length > 0 ?
                            this.props.agent[0].first_name + " " + this.props.agent[0].last_name : ''}
                    </Typography>
                    <Typography variant="h5" component="h3">
                        {this.props.agent.length > 0 ?
                            this.props.agent[0].phone_number : ''}
                    </Typography>
                    {
                        this.props.role === "SuperUser" || this.props.role === "BStaff"?
                            <div className="action-buttons">
                                <RemoveDialogue
                                    itemType="profile"
                                    itemId={this.props.match.params.agent_id}>
                                </RemoveDialogue>
                                <Link 
                                    to={`/vendors/${this.props.match.params.vendor_id}/agents/${this.props.match.params.agent_id}/edit`} >
                                    <Button>
                                        Edit This Agent
                                    </Button>
                                </Link>
                            </div>
                            : ''
                    }
                </Paper>
                {this.state.agent.length > 0 ?

                    <AgentList agent={this.props.agent[0] ? this.props.agent[0]._id : ''}></AgentList>
                    : ''}
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        agent: state.agen.agent,
        role : state.auth.role
    }
}

export default
    compose(
        withStyles(styles),
        withRouter,
        connect(mapStateToProps, actions)
    )(Agents);