import {
    CREATE_VENDOR,
    VENDOR_ERROR,
    VENDOR_LIST,
    VENDOR
} from "../actions/types";

const DEFAULT_STATE = {
    vendor: {},
    vendors: [],
    errorMessage: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case CREATE_VENDOR:
            return { ...state,
                vendor: action.payload.vendor,
                vendors: action.payload.vendors,
                errorMessage: ''
            }
        case VENDOR_LIST:
            return { ...state,
                vendors: action.payload,
                errorMessage: ''
            }
        case VENDOR:
            return { ...state,
                vendor: action.payload,
                errorMessage: ''
            }
        case VENDOR_ERROR:
             
            return { ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }

}