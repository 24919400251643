export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";

export const CREATE_VENDOR = "CREATE_VENDOR";
export const VENDOR_REMOVE = "VENDOR_REMOVE";
export const VENDOR_LIST = "VENDOR_LIST";
export const VENDOR = "VENDOR";
export const VENDOR_EDIT = "VENDOR_EDIT";
export const VENDOR_ERROR = "VENDOR_ERROR";


export const AGENT_CREATE = "AGENT_CREATE";
export const AGENT_REMOVE = "AGENT_REMOVE";
export const AGENT_LIST = "AGENT_LIST";
export const AGENT_EDIT = "AGENT_EDIT";
export const AGENT = "AGENT";
export const AGENT_ERROR = "AGENT_ERROR";


export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT = "PROJECT";
export const PROJECT_ERROR = "PROJECT_ERROR";

export const BSTAFF_LIST = "BSTAFF_LIST";
export const BSTAFF = "BSTAFF";
export const BSTAFF_ERROR = "BSTAFF_ERROR";
export const BSTAFF_CREATE = "BSTAFF_CREATE";
export const BSTAFF_REMOVE = "BSTAFF_REMOVE";
export const PROFILE = "PROFILE";


export const COMMENT_CREATE = "COMMENT_CREATE";
export const COMMENT_LIST = "COMMENT_LIST";
export const COMMENT_ERROR = "COMMENT_ERROR";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const PHASE_LIST = "PHASE_LIST";
export const PHASE_MEDIA = "PHASE_MEDIA";

export const MARK_AS_READ = "MARK_AS_READ";