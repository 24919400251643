import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import * as actions from "../../actions";
import { Typography } from '@material-ui/core';

class ResponsiveDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

   handleRemove = async ()=>{
      await this.props.removeAgent(this.props.itemType, this.props.itemId);
      if(!this.props.errorMessage){
          this.props.history.push("/vendors")
      }
  }

  
  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Button onClick={this.handleClickOpen} color="secondary" >Remove this agent</Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Delete?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can't undo this opretion
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleRemove} color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
          {
              this.props.errorMessage?
              <Typography>unable to remove</Typography>:''
          }
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        errorMessage: state.agen.errorMessage,
        agent: state.agen.agent
    }
}


export default
    compose(
        withRouter,
        connect(mapStateToProps, actions),
        withMobileDialog()
    )(ResponsiveDialog);