import {
    GET_NOTIFICATIONS, MARK_AS_READ
} from "../actions/types";

const DEFAULT_STATE = {
    notifications: [],
    errorMessage: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return { ...state,
                notifications: action.payload,
                errorMessage: ''
            }
        case MARK_AS_READ:
            return { ...state,
                errorMessage: ''
            }    
        default:
            return state;
    }

}