import axios from "axios";

import {
    AUTH_LOGIN,
    AUTH_ERROR,
    AUTH_LOGOUT,
    VENDOR_ERROR,
    CREATE_VENDOR,
    VENDOR_LIST,
    VENDOR,
    VENDOR_REMOVE,
    AGENT_CREATE,
    AGENT_ERROR,
    AGENT_LIST,
    AGENT_REMOVE,
    AGENT,
    PROJECT,
    PROJECT_LIST,
    PROJECT_ERROR,
    BSTAFF_LIST,
    BSTAFF_ERROR,
    COMMENT_LIST,
    COMMENT_ERROR,
    GET_NOTIFICATIONS,
    VENDOR_EDIT,
    AGENT_EDIT,
    BSTAFF,
    PROFILE,
    BSTAFF_REMOVE,
    BSTAFF_CREATE,
    PHASE_MEDIA,
    MARK_AS_READ
} from "./types";
import { COMMENT } from "postcss-selector-parser";

const base_url = "https://api.be-nrg.com/"

export const logIn = (data) => {
    return async (dispatch) => {
        try {
            const res = await axios.post("https://api.be-nrg.com/login", data);

            dispatch({
                type: AUTH_LOGIN,
                payload: res.data
            });

            localStorage.setItem("JWT_TOKEN", res.data.token);
            localStorage.setItem("role", res.data.user.role);
            localStorage.setItem("id", res.data.profile._id);
            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
        } catch (error) {
            dispatch({
                type: AUTH_ERROR,
                payload: "Wrong email password combination"
            })
        }
    }
}



export const logOut = () => {
    return dispatch => {
        localStorage.removeItem('JWT_TOKEN');
        localStorage.removeItem('role');
        localStorage.removeItem('id');
        dispatch({
            type: AUTH_LOGOUT,
            payload: ''
        });
    }
}


export const getVendors = () => {
    return async dispatch => {
        try {
            const res = await axios.get("https://api.be-nrg.com/vendor/meta");

            dispatch({
                type: VENDOR_LIST,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}

export const getVendor = (vendor_id) => {
    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/vendor/${vendor_id}`);

            console.log(res)
            const email = await axios.get(`https://api.be-nrg.com/users/${res.data[0].user}`);

            res.data[0].email = email.data.email

            dispatch({
                type: VENDOR,
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}

export const updateVendor = (vendor_id, data, user_id) => {
    return async dispatch => {
        try {
            const res = await axios.patch(`https://api.be-nrg.com/vendor/${vendor_id}`, data );

            const email_change = await axios.put(`https://api.be-nrg.com/users/${user_id}`, {email: data.email} )
            console.log(email_change)

            dispatch({
                type: VENDOR_EDIT,
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to update vendors"
            })
        }

    }
}

export const createVendor = (data) => {
    return async (dispatch) => {
        try {            
            const venderInfo = {
                organization_name: data.organization_name,
                description : data.description,
                email : data.email,
                password: data.password,
                phone_number : data.phone_number,
                bstaff: data.bstaff,
                address: {
                    addressLineOne: data.addressLineOne,
                    addressLineTwo: data.addressLineTwo,
                    locality: data.locality,
                    region: data.region,
                    country: data.country
                },
            }
            

            const res = await axios.post("https://api.be-nrg.com/users/vendor", venderInfo);


            dispatch({
                type: CREATE_VENDOR,
                payload: res.data
            });

        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to create vendors"
            })
        }
    }
}

export const remove = (type, id) =>{
    return async (dispatch)=>{
        try {
            const res = await axios.delete(`https://api.be-nrg.com/${type}/${id}`)
            dispatch({
                type: VENDOR_REMOVE,
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to create vendors"
            })
        }
    }
}

export const getAgents = (vendor_id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/profile/agents/${vendor_id}`);

            dispatch({
                type: AGENT_LIST,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}

export const getAgent = (agentId) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/profile/${agentId}`);

            dispatch({
                type: AGENT,
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: AGENT_ERROR,
                payload: "failed to fetch agents"
            })
        }

    }
}

export const updateAgent = (agentId, data) => {

    return async dispatch => {
        try {
            const res = await axios.patch(`https://api.be-nrg.com/profile/${agentId}`, data);

            dispatch({
                type: AGENT_EDIT,
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: AGENT_ERROR,
                payload: "failed to fetch agents"
            })
        }

    }
}

export const createAgent = (data) => {
    return async (dispatch) => {
        try {          
            
            
            data.pin = Math.floor(Math.random() * 10000).toString(); 

            await axios.post("https://api.be-nrg.com/users/agent", data);

            

            dispatch({
                type: AGENT_CREATE,
                payload: data
            });

        } catch (error) {
            dispatch({
                type: AGENT_ERROR,
                payload: "failed to create agent"
            })
        }
    }
}

export const removeAgent = (type, id) =>{
    return async (dispatch)=>{
        try {
            const res = await axios.delete(`https://api.be-nrg.com/${type}/${id}`)
            dispatch({
                type: AGENT_REMOVE,
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: AGENT_REMOVE,
                payload: "failed to create vendors"
            })
        }
    }
}

export const getProjects = (agent_id) => {
    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/project/agent/${agent_id}`);


            dispatch({
                type: PROJECT_LIST,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: PROJECT_ERROR,
                payload: "failed to fetch projects"
            })
        }

    }
}

export const getProject = (project_id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/project/${project_id}`);

            dispatch({
                type: PROJECT,
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: PROJECT_ERROR,
                payload: "failed to fetch agents"
            })
        }

    }
}


export const getBStaff = () => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/bstaff`);

            dispatch({
                type: BSTAFF_LIST,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: BSTAFF_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}

export const getProfile = (id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/bstaff/${id}`);

            dispatch({
                type: PROFILE,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: BSTAFF_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}

export const getOneBStaff = (id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/bstaff/${id}`);

            dispatch({
                type: BSTAFF,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: BSTAFF_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}

export const removeBStaff = (id) => {

    return async dispatch => {
        try {
            const res = await axios.delete(`https://api.be-nrg.com/bstaff/${id}`);

            dispatch({
                type: BSTAFF_REMOVE,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: BSTAFF_ERROR,
                payload: "failed to delete bstaff"
            })
        }

    }
}

export const createBStaff = (data) => {

    return async dispatch => {
        try {
            const res = await axios.post(`https://api.be-nrg.com/users/bstaff`, data);

            dispatch({
                type: BSTAFF_CREATE,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: BSTAFF_ERROR,
                payload: "failed to create bstaff"
            })
        }

    }
}


export const getComments = (phase_id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/phases/${phase_id}/commnets`);

            dispatch({
                type: COMMENT_LIST,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: COMMENT_ERROR,
                payload: "failed to fetch comments"
            })
        }

    }
}

export const getPhases = (project_id, phase) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/phases`);

            dispatch({
                type: COMMENT_LIST,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: COMMENT_ERROR,
                payload: "failed to fetch comments"
            })
        }

    }
}

export const getMedias = (phase_id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/phases/${phase_id}/media`);

            dispatch({
                type: PHASE_MEDIA,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: COMMENT_ERROR,
                payload: "failed to fetch comments"
            })
        }

    }
}



export const comment = (project_id, phase, data, id) => {

    return async dispatch => {
        try {

            data.phase_id = phase;
            data.profile_id = id;
            data.code = Date.now();
            const res = await axios.post(`https://api.be-nrg.com/comments`, data);
            dispatch({
                type: COMMENT,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: COMMENT_ERROR,
                payload: "failed to post comment"
            })
        }

    }
}


export const getNotifications= (id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/notifications/data/${id}`);

            dispatch({
                type: GET_NOTIFICATIONS,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}

export const markAsRead = (id) => {

    return async dispatch => {
        try {
            const res = await axios.get(`https://api.be-nrg.com/notifications/data/${id}/markAsRead`);

            dispatch({
                type: MARK_AS_READ,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: VENDOR_ERROR,
                payload: "failed to fetch vendors"
            })
        }

    }
}