import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import { withRouter } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from "redux";
import { connect } from "react-redux";

import Layout from "../includes/Layout"

import * as actions from "../../actions";

const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});



class CreateAgent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addressLineOne: "",
			addressLineTwo: "",
			country: "",
			locality: "",
			region: "",
			first_name: "",
			last_name: "",
			phone_number: ""
		}
	}
	onSubmit = async () => {
		const { match: {params : { vendor_id, agent_id}}} = this.props;
		const address = {
			addressLineOne: this.state.addressLineOne,
			addressLineTwo: this.state.addressLineTwo,
			country: this.state.country,
			locality: this.state.locality,
			region: this.state.region,
		}
		const data = this.state;

		data.address = address;

		await this.props.updateAgent(agent_id, data);
		if(!this.props.errorMessage)
			this.props.history.push(`/vendors/${vendor_id}/agents/${agent_id}/projects`);
	}
	async componentDidMount() {
		await this.props.getAgent(this.props.match.params.agent_id);
		let { agent } = this.props;
		agent = agent[0];
		this.setState({
			addressLineOne: agent.address ? agent.address.addressLineOne : "",
			addressLineTwo: agent.address ? agent.address.addressLineOne : "",
			country: agent.address ? agent.address.country : "",
			locality: agent.address ? agent.address.locality : "",
			region: agent.address ? agent.address.region : "",
			first_name: agent.first_name,
			last_name: agent.last_name,
			phone_number: agent.phone_number
		})
	}

	render() {
		const { classes } = this.props;
		return (
			<Layout>
				<CssBaseline />
				<Paper className={classes.paper}>
					<Typography component="h1" variant="h5">
						Create an agent
          			</Typography>

					<form className={classes.form}>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="first_name">First Name</InputLabel>
							<Input
								id="first_name" 
								name="first_name"
								value={this.state.first_name}
								onChange={e => this.setState({first_name: e.target.value})}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="last_name">Last Name</InputLabel>
							<Input
								id="last_name" 
								name="last_name"
								value={this.state.last_name}
								onChange={e => this.setState({last_name: e.target.value})}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="phone_number">Phone Number</InputLabel>
							<Input
								name="phone_number"
								id="phone_number"
								value={this.state.phone_number}
								onChange={e => this.setState({phone_number: e.target.value})}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="addressLineOne">Address Line One</InputLabel>
							<Input
								id="addressLineOne" 
								name="address['addressLineOne']"
								value={this.state.addressLineOne}
								onChange={e => this.setState({addressLineOne: e.target.value})}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="addressLineTwo">Address Line Two</InputLabel>
							<Input
								id="addressLineTwo" 
								name="address['addressLineTwo']"
								value={this.state.addressLineTwo}
								onChange={e => this.setState({addressLineTwo: e.target.value})}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="locality">Locality</InputLabel>
							<Input
								id="locality" 
								name="address['locality']"
								value={this.state.locality}
								onChange={e => this.setState({locality: e.target.value})}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="region">Region</InputLabel>
							<Input
								id="region" 
								name="address['region']"
								value={this.state.region}
								onChange={e => this.setState({region: e.target.value})}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="country">Country</InputLabel>
							<Input
								id="country" 
								name="address['country']"
								value={this.state.country}
								onChange={e => this.setState({country: e.target.value})}
							/>
						</FormControl>

						{
							this.props.errorMessage ?
								<Typography variant="caption" color="error" gutterBottom>
									{this.props.errorMessage}
								</Typography> :
								null
						}

						<Button
							type="button"
							variant="contained"
							color="primary"
							onClick={this.onSubmit}
							className={classes.submit}
						>
							Update
           				</Button>
					</form>

				</Paper>

			</Layout>
		);
	}


}

CreateAgent.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		errorMessage: state.agen.errorMessage,
		agent_new: state.agen.agent_new,
		agent: state.agen.agent
	}
}

export default compose(
	connect(mapStateToProps, actions),
	withRouter,
	withStyles(styles),
)(CreateAgent);