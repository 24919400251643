import {
    COMMENT_CREATE,
    COMMENT_LIST,
    COMMENT_ERROR,
} from "../actions/types";

const DEFAULT_STATE = {
    comment: {},
    comments: [],
    errorMessage: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case COMMENT_CREATE:
            return { ...state,
                comment: action.payload.vendor,
                errorMessage: ''
            }
        case COMMENT_LIST:
            return { ...state,
                comments: action.payload,
                errorMessage: ''
            }
        case COMMENT_ERROR:
             
            return { ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }

}