import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from "redux-form";
import {compose} from "redux";
import { connect } from "react-redux";

import * as actions from "../../actions";
const validate = values => {
	const errors = {}
	const requiredFields = [
	  'firstName',
	  'lastName',
	  'email',
	  'favoriteColor',
	  'notes'
	]
	requiredFields.forEach(field => {
	  if (!values[field]) {
		errors[field] = 'Required'
	  }
	})
	if (
	  values.email &&
	  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
	  errors.email = 'Invalid email address'
	}
	return errors
  }

const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

 export const renderInput = ({
	input,
	label,
	meta: { touched, error },
	...custom
  }) => (
	<Input
	  {...input}
	  {...custom}
	/>
  )

class SignIn extends React.Component {
	constructor(props){
        super(props);
		this.onSubmit = this.onSubmit.bind(this);
	}
	async onSubmit(data){
		await this.props.logIn(data);
		if(!this.props.errorMessage)
			this.props.history.push('/');
	}
	render() {
		const { classes } = this.props;
		const {handleSubmit} = this.props;
		return (
			<React.Fragment>
				<CssBaseline />
				<main className={classes.layout}>
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
          				</Typography>
						<form className={classes.form} onSubmit={handleSubmit(this.onSubmit)}>
							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="email">Email Address</InputLabel>
								<Field 
									id="email" name="email" autoComplete="email" autoFocus 
									component={renderInput}
								/>
							</FormControl>
							<FormControl margin="normal" required fullWidth>
								<InputLabel htmlFor="password">Password</InputLabel>
								<Field
									name="password"
									type="password"
									id="password"
									autoComplete="current-password"
									component={renderInput}
								/>
							</FormControl>
							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/>
							{
								this.props.errorMessage ?
									<Typography variant="caption" gutterBottom>
										{this.props.errorMessage}
									</Typography>:
									null
							}
							 
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Sign in
            				</Button>
						</form>
					</Paper>
				</main>
			</React.Fragment>
		);
	}


}

SignIn.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
	return {
		errorMessage: state.auth.errorMessage
	}
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles), 
	reduxForm({form: "SignIn"},validate)
) (SignIn);