import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Layout from '../includes/Layout';
import BstaffList from './list';
import * as actions from '../../actions';

const styles = theme => ({
    root: {
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    }
})

class Vendors extends React.Component {
    constructor(props){
        super(props);
        this.gotoCreate = this.gotoCreate.bind(this);
    }

    async componentDidMount(){
    }

    componentDidUpdate(){
    }

    gotoCreate(){
        this.props.history.push("/bstaff/new")
    }
    render(){
        const { classes } = this.props;

        return(
            <Layout>
                <Toolbar>
                    <Typography className={classes.grow} component="h2" variant="display1">
                        Bstaff List
                    </Typography>
                    <Button variant="contained" color="primary" onClick={this.gotoCreate} >
                        Create Bstaff
                    </Button>
                </Toolbar>
                <BstaffList />
            </Layout>
        )
    }
}
function mapStateToProps(state) {
	return {
		errorMessage: state.auth.errorMessage,
        id: state.auth.id,
        vendors : state.vend.vendors
	}
}

export default 
    compose(
        withRouter,
        withStyles(styles),
        connect(mapStateToProps, actions)
    )(Vendors);