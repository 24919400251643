import React, { Component } from 'react'
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import Layout from '../includes/Layout';
import * as actions from '../../actions';

const styles = theme => ({
    root: {
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    }
})

class show extends Component {
    async componentDidMount(){
        const { match: {params: {bstaff_id} } } = this.props
        await this.props.getOneBStaff(bstaff_id);
        await this.props.getProfile(bstaff_id);

        console.log(this.props.bstaff);
        console.log(this.props.profile);
    }

    render() {
        return (
            <Layout>

            </Layout>
        )
    }
}

function mapStateToProps(state) {
	return {
		errorMessage: state.auth.errorMessage,
        id: state.auth.id,
        profile: state.auth.profile,
        bstaff : state.bsta.bstaff
	}
}

export default 
    compose(
        withRouter,
        withStyles(styles),
        connect(mapStateToProps, actions)
    )(show);