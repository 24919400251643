import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import Layout from '../includes/Layout';
import PhasesList from './list';
import * as actions from '../../actions';

const styles = theme => ({
    root: {
        width: 'fit-content',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary
    }    
});

class Agents extends React.Component {
    constructor(props) {
        super(props);
        this.gotoCreate = this.gotoCreate.bind(this);
    }

    async componentDidMount() {
        await this.props.getProject(this.props.match.params.project_id);
    }

    componentDidUpdate() {
    }

    gotoCreate() {
        this.props.history.push(`/vendors/${this.props.match.params.vendor_id}/agents/new`)
    }
    render() {
        const { classes } = this.props;
        return (
            <Layout>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Title : {this.props.project.length > 0 ?
                                this.props.project[0].title : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            Start Date : {this.props.project.length > 0 ? 
                                new Date(this.props.project[0].start_date).toDateString() : ''}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {this.props.project.length > 0 ? this.props.project[0].description : ''}                           
                        </Typography>
                        <Divider />
                        <Typography variant="h6" component="h3">
                            Installation Adress
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b> Address Line 1 : </b>{this.props.project.length > 0 ? 
                                this.props.project[0].installation_address.addressLineOne : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b>Address Line 2 : </b> {this.props.project.length > 0 ? 
                                this.props.project[0].installation_address.addressLineTwo : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b> Locality : </b>{this.props.project.length > 0 ? 
                                this.props.project[0].installation_address.locality : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b>Region : </b> {this.props.project.length > 0 ? 
                                this.props.project[0].installation_address.region : ''}
                        </Typography>
                        <Divider />
                        <Typography variant="h6" component="h3">
                            Customer Information
                        </Typography>

                        <Typography className={classes.pos} color="textSecondary">
                            <b>First Name : </b> {this.props.project.length > 0 ? 
                                this.props.project[0].customer_id.first_name : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b>Last Name : </b> {this.props.project.length > 0 ? 
                                this.props.project[0].customer_id.last_name : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b> Phone Number : </b>{this.props.project.length > 0 ? 
                                this.props.project[0].customer_id.phone_number : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b>Address Line 1 : </b>  {this.props.project.length > 0 ? 
                                this.props.project[0].customer_id.address.addressLineOne : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b>Address Line 2 :</b>  {this.props.project.length > 0 ? 
                                this.props.project[0].customer_id.address.addressLineTwo : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b> Locality : </b>{this.props.project.length > 0 ? 
                                this.props.project[0].customer_id.address.locality : ''}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            <b>Region : </b> {this.props.project.length > 0 ? 
                                this.props.project[0].customer_id.address.region : ''}
                        </Typography>
                    </CardContent>
                </Card>
                
                <PhasesList
                    phases={
                        this.props.project.length > 0 ?
                            this.props.project[0].phases : []}
                >
                </PhasesList>
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        project: state.proj.project
    }
}

export default
    compose(
        withStyles(styles),
        withRouter,
        connect(mapStateToProps, actions)
    )(Agents);