import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reduxThunk from 'redux-thunk';
import axios from "axios";

import App from './components/App';
import Vendor from './components/vendors';
import VendorNew from './components/vendors/new';
import VendorEdit from './components/vendors/edit';
import Bstaff from './components/bstaff';
import BstaffNew from './components/bstaff/new';
import SingleBstaff from './components/bstaff/show';
import SingleVendor from './components/agents';
import AgentNew from './components/agents/new';
import AgentEdit  from './components/agents/edit';
import SingleAgent from './components/projects';
import SingleProject from './components/phases';
import Notifications  from "./components/notifications"
import Login from "./components/auth/Login";
import reducers from './reducers';
import authGuard from './components/HOCs/authGuard';
import bstaffGuard from './components/HOCs/bStaffGuard';

import * as serviceWorker from './serviceWorker';

const jwtToken = localStorage.getItem("JWT_TOKEN");
const role = localStorage.getItem("role");
const id = localStorage.getItem("id");

axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;

ReactDOM.render(
    <Provider store={createStore(reducers, {
        auth: {
            token: jwtToken,
            isAuthenticated: jwtToken ? true : false,
            role: role,
            id
        }

    }, applyMiddleware(reduxThunk))}>
        <BrowserRouter>
            <App>
                <Route path="/" exact component={bstaffGuard(Vendor)} />
                <Route path="/vendors" exact component={bstaffGuard(Vendor)} />
                <Route path="/vendors/new" exact component={authGuard(VendorNew)} />
                <Route 
                    path="/vendors/:vendor_id/agents" 
                    exact 
                    component={authGuard(SingleVendor)} 
                />
                <Route 
                    path="/vendors/:vendor_id/edit" 
                    exact 
                    component={bstaffGuard(VendorEdit)} 
                />
                <Route 
                    path="/vendors/:vendor_id/agents/new" 
                    exact 
                    component={authGuard(AgentNew)} 
                />
                <Route 
                    path="/vendors/:vendor_id/agents/:agent_id/projects" 
                    exact 
                    component={authGuard(SingleAgent)} 
                />
                <Route 
                    path="/vendors/:vendor_id/agents/:agent_id/edit" 
                    exact 
                    component={bstaffGuard(AgentEdit)} 
                />
                <Route 
                    path="/bstaff" 
                    exact 
                    component={bstaffGuard(Bstaff)} 
                />
                <Route 
                    path="/bstaff/new" 
                    exact 
                    component={bstaffGuard(BstaffNew)} 
                />
                <Route 
                    path="/bstaff/:bstaff_id/show" 
                    exact 
                    component={bstaffGuard(SingleBstaff)} 
                />
                <Route 
                    path="/vendors/:vendor_id/agents/:agent_id/projects/:project_id" 
                    exact 
                    component={authGuard(SingleProject)} 
                />
                <Route 
                    path="/notifications" 
                    exact 
                    component={authGuard(Notifications)} 
                />
                <Route path="/login" exact component={Login} />
            </App>
        </BrowserRouter>
    </Provider>

    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
