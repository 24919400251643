import {    
    PROJECT_ERROR,
    PROJECT_LIST,
    PROJECT
} from "../actions/types";

const DEFAULT_STATE = {
    project: {},
    projects: [],
    errorMessage: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case PROJECT_LIST:
            return { ...state,
                projects: action.payload,
                errorMessage: ''
            }
        case PROJECT:
            return { ...state,
                project: action.payload,
                errorMessage: ''
            }
        case PROJECT_ERROR:
             
            return { ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }

}