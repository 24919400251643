import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from "redux";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import { withRouter } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import instalation from "./installation.json";
import traning from "./training.json";

import * as actions from "../../actions/";



function Transition(props) {
  return <Slide direction="up" {...props} />;
}


export const renderInput = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
    <Input
      {...input}
      {...custom} />
  )

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
    padding: "10px"
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  card: {
    margin: 'auto',
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  imgHalf: {
    width: '300px',
    float: 'left'
  }
});

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: instalation.map(ins => {
        return {
          id: ins.installation_index,
          title: ins.title.en
        }
      }),
      page: 0,
      rowsPerPage: 24,
      trainRows: traning.map(tra => {
        return {
          id: tra.step_number,
          title: tra.step_name.en
        }
      }),
      trainPage: 0,
      trainRowsPerPage: 24,
      open: false,
      currentPhase: {
        id: "",
        media: []
      },
      disableBtn: false
    };
  }

  handleClickOpen = async (phase) => {
    await this.props.getMedias(phase)
    this.setState({
      currentPhase: {
        id: phase,
        media: this.props.medias
      }
    })
    this.setState({ open: true });
    await this.props.getComments(phase);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onSubmit = async (data) => {
    this.setState({ disableBtn: true })
    const project_id = this.props.match.params.project_id;
    await this.props.comment(project_id, this.state.currentPhase.id, data, this.props.id);
    this.props.reset();
    if (!this.props.errorMessage)
      this.props.getComments(this.state.currentPhase.id);
    this.setState({ disableBtn: false })
  }


  async componentDidMount() {
    await this.props.getProject(this.props.match.params.project_id);


    if (this.props.project[0].installation_phases && this.props.project[0].installation_phases.length > 0) {
      console.log(this.props.project[0].installation_phases)
      for (let i = 0; i < this.state.rows.length; i++) {


        let phase = this.getPhase(this.state.rows[i].id, this.props.project[0].installation_phases)
        console.log(this.state.rows[i])
        if (phase) {

          this.state.rows[i].status = phase.phase_status;
          this.state.rows[i].phase_id = phase._id
        }

      }
    }

    if (this.props.project[0].training_phases && this.props.project[0].training_phases.length > 0) {
      console.log(this.props.project[0].training_phases)
      for (let i = 0; i < this.state.trainRows.length; i++) {


        let phase = this.getPhase(i+1, this.props.project[0].training_phases)
        console.log(phase)
        if (phase) {

          this.state.trainRows[i].status = phase.phase_status;
          this.state.trainRows[i].phase_id = phase._id
        }

      }
    }

    this.setState({
      rows: this.state.rows
    })
  }

  getPhase = (i, phase) => {
    let to_return = undefined;
    to_return  = phase.find(p =>  p.phase_number === i  );

    return to_return;
  }

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page, trainRows, trainRowsPerPage, trainPage } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const { handleSubmit } = this.props;

    if (this.state.rows === []) {
      return (
        <Paper className={classes.root}>
          <CircularProgress className={classes.progress} />

        </Paper>
      )
    }
    else {
      return (
        <React.Fragment>
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
            <Typography variant="h5" component="h3">
              Training Phases
            </Typography>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Num</TableCell>
                    <TableCell>Phase</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainRows.slice(trainPage * trainRowsPerPage, page * trainRowsPerPage + trainRowsPerPage).map(trainRow => {
                    return (
                      <TableRow
                        key={trainRow.phase_id}
                        onClick={() => this.handleClickOpen(trainRow.phase_id)}
                      >
                        <TableCell>{trainRow.id}</TableCell>
                        <TableCell component="th" scope="trainRow">
                          {trainRow.title}
                        </TableCell>
                        <TableCell>{trainRow.status}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 48 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

              </Table>


            </div>
          </Paper>
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Typography variant="h5" component="h3">
                Installation Phases
              </Typography>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Num</TableCell>
                    <TableCell>Phase</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                    return (
                      <TableRow
                        key={row.phase_id}
                        onClick={() => this.handleClickOpen(row.phase_id)}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 48 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                <Dialog
                  fullScreen
                  open={this.state.open}
                  onClose={this.handleClose}
                  TransitionComponent={Transition}
                >
                  <AppBar className={classes.appBar}>
                    <Toolbar>
                      <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" color="inherit" className={classes.flex}>
                        Comments
                  </Typography>
                    </Toolbar>
                  </AppBar>
                  <Paper>
                    {
                      this.state.currentPhase.media && this.state.currentPhase.media.length > 0 ?
                        this.state.currentPhase.media.map(image =>
                          <img src={image.url} alt="biogas" className={classes.imgHalf} />
                        )


                        : 'No images available'
                    }


                  </Paper>



                  <List>
                    {
                      this.props.comments ?
                        this.props.comments.map((comment) => {
                          return [
                            <ListItem button key="list">
                              <ListItemText primary={comment.profile ?
                                comment.profile.first_name + " " + comment.profile.last_name : ''} secondary={comment.comment} />
                            </ListItem>,
                            <Divider key="devider" />
                          ]
                        })
                        : ''
                    }

                    <ListItem>

                      <form className={classes.form} onSubmit={handleSubmit(this.onSubmit)} ref={(el) => this.myFormRef = el}>

                        <FormControl margin="normal" required fullWidth>
                          <InputLabel htmlFor="comment">Comment</InputLabel>
                          <Field
                            name="comment"
                            id="comment"
                            component={renderInput}
                          />
                        </FormControl>

                        {
                          this.props.errorMessage ?
                            <Typography variant="caption" color="error" gutterBottom>
                              {this.props.errorMessage}
                            </Typography> :
                            null
                        }

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={this.state.disableBtn}
                        >
                          Send
           				      </Button>
                      </form>

                    </ListItem>
                  </List>
                </Dialog>
              </Table>


            </div>
          </Paper>

        </React.Fragment>
      );
    }
  }
}

ProjectList.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    project: state.proj.project,
    comments: state.comm.comments,
    medias: state.phase.medias,
    id: state.auth.id
  }
}


export default compose(
  connect(mapStateToProps, actions),
  withRouter,
  withStyles(styles),
  reduxForm({ form: "CreateAgent" })
)(ProjectList);