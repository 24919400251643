import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Layout from '../includes/Layout';
import VendorList from './list';
import * as actions from '../../actions';

const styles = theme => ({
    root: {
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    }
})

class Notification extends React.Component {
    constructor(props){
        super(props);
        this.markAsread = this.markAsread.bind(this);
    }

    async componentDidMount(){
        this.props.getNotifications(this.props.id)
    }

    componentDidUpdate(){
    }

    markAsread(){

    }
    render(){
        const { classes } = this.props;

        return(
            <Layout>
                <Toolbar>
                    <Typography className={classes.grow} component="h2" variant="display1">
                        Notifications
                    </Typography>
                </Toolbar>
                <VendorList></VendorList>
            </Layout>
        )
    }
}
function mapStateToProps(state) {
	return {
		errorMessage: state.auth.errorMessage,
        id: state.auth.id,
        notifications : state.note.notifications
	}
}

export default 
    compose(
        withRouter,
        withStyles(styles),
        connect(mapStateToProps, actions)
    )(Notification);