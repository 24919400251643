import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";

import Layout from "../includes/Layout"

import * as actions from "../../actions";
const validate = values => {
	const errors = {}
	const requiredFields = [
		'firstName',
		'lastName',
		'email',
		'favoriteColor',
		'notes'
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Required'
		}
	})
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'Invalid email address'
	}
	return errors
}

const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

export const renderInput = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => (
		<Input
			{...input}
			{...custom}
		/>
	)

class CreateVendor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sex : ''
		}
		this.onSubmit = this.onSubmit.bind(this);
	}
	async onSubmit(data) {
		data.bstaff = this.props.id;
		data.sex = this.state.sex;
		console.log(data);
		await this.props.createBStaff(data);
		if (!this.props.errorMessage)
			this.props.history.push('/bstaff');
	}
	componentDidMount() {
		console.log(this.props.id);
	}
	render() {
		const { classes } = this.props;
		const { handleSubmit } = this.props;
		return (
			<Layout>
				<CssBaseline />
				<Paper className={classes.paper}>
					<Typography component="h1" variant="h5">
						Create a new Bstaff
          			</Typography>
					<form className={classes.form} onSubmit={handleSubmit(this.onSubmit)}>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="first_name">First name</InputLabel>
							<Field
								id="first_name" 
								name="first_name"
								component={renderInput}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="last_name">Last name</InputLabel>
							<Field
								id="last_name" name="last_name"
								component={renderInput}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="location">Location</InputLabel>
							<Field
								id="location" name="location"
								component={renderInput}
							/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="email">Email Address</InputLabel>
							<Field
								id="email" name="email" 
								component={renderInput}
							/>
						</FormControl>
						<FormControl>
							<InputLabel htmlFor="sex">Gender</InputLabel>
							<Select
								value={this.state.sex}
								onChange={(e)=> this.setState({sex: e.target.value})}
								displayEmpty
								name="sex"
								className={classes.selectEmpty}
							>
								<MenuItem value="">
								</MenuItem>
								<MenuItem value={"M"}>Male</MenuItem>
								<MenuItem value={"F"}>Female</MenuItem>
							</Select>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="password">Password</InputLabel>
							<Field
								name="password"
								type="password"
								id="password"
								component={renderInput}
							/>
						</FormControl>
						

						{
							this.props.errorMessage ?
								<Typography variant="caption" gutterBottom>
									{this.props.errorMessage}
								</Typography> :
								null
						}

						<Button
							type="submit"
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Create
            			</Button>
					</form>
					{
						this.props.errorMessage?
						<Typography>{this.props.errorMessage}</Typography>:''
					}
				</Paper>

			</Layout>
		);
	}


}

CreateVendor.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		errorMessage: state.bsta.errorMessage,
		id: state.auth.id
	}
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	reduxForm({ form: "CreateVendor" }, validate)
)(CreateVendor);