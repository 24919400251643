import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from './auth';
import vendReducer from "./vend";
import agenReducer from "./agen";
import projReducer from "./proj";
import commReducer from "./comm";
import notiReducer from "./notf";
import bstaffReducer from "./bstaff";
import phaseReducer from "./phase";


export default combineReducers({
    form: formReducer,
    auth: authReducer,
    vend: vendReducer,
    agen: agenReducer,
    proj: projReducer,
    comm: commReducer,
    note: notiReducer,
    bsta: bstaffReducer,
    phase: phaseReducer
});