import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import Layout from '../includes/Layout';
import AgentList from './list';
import RemoveDialogue from '../_commons/removeDialogue';
import * as actions from '../../actions';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class Agents extends React.Component {
    constructor(props) {
        super(props);
        this.gotoCreate = this.gotoCreate.bind(this);
    }

    async componentDidMount() {
        await this.props.getVendor(this.props.match.params.vendor_id);
    }

    componentDidUpdate() {
    }

    gotoCreate() {
        this.props.history.push(`/vendors/${this.props.match.params.vendor_id}/agents/new`)
    }
    render() {
        const { classes } = this.props;
        return (
            <Layout>
                
                
                <Paper className={classes.root} elevation={1}>
                    <Typography variant="h5" component="h3">

                        {this.props.vendor &&this.props.vendor.length > 0 ? this.props.vendor[0].organization_name : ''}
                    </Typography>
                    <Typography component="p">
                        {this.props.vendor &&this.props.vendor.length > 0 ? this.props.vendor[0].description : ''}
                    </Typography>
                    {

                    }
                    {
                        this.props.role === "SuperUser" || this.props.role === "BStaff"?
                            <div className="action-buttons">
                               <RemoveDialogue 
                                itemType="vendor" 
                                itemId={this.props.vendor && 
                                    this.props.vendor.length > 0 ?
                                    this.props.match.params.vendor_id : 
                                    ''
                                } 
                            />
                            <Link to={`/vendors/${this.props.match.params.vendor_id}/edit`} >
                                <Button>
                                    Edit This Vendor
                                </Button>
                            </Link> 
                            </div>
                            
                                : ''
                    }
                </Paper>
                <Button variant="contained" color="primary" onClick={this.gotoCreate} >
                    Create An Agent
                </Button>
                <AgentList></AgentList>
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.errorMessage,
        id: state.auth.id,
        vendor: state.vend.vendor,
        role : state.auth.role
    }
}

export default
    compose(
        withStyles(styles),
        withRouter,
        connect(mapStateToProps, actions)
    )(Agents);